import { useLiveQuery } from 'dexie-react-hooks';
import React, { useState, useEffect, createContext, useContext, useRef } from 'react';
import { db } from './persistence/db';

//const Buffer = require('buffer/').Buffer;

const KcalContext = createContext();

export function useKcalManager() {
    return useContext(KcalContext);
}

export const KcalProvider = (props) => {


    function loadUserBio() {

        // TODO GDPR
        let localUserBio = localStorage.getItem('userProfile');

        if (localUserBio === null) {
            localUserBio = {
                /*name: 'John Doe',*/
                age: 30,
                weight: 78,
                height: 180,
                gender: 1,
                activityLevel: 1.2,
                kcalBalance: 200,
                kcalGoal: 2300,
                proteinGoal: 150
            }
        } else {
            localUserBio = JSON.parse(localUserBio);
        }

        return localUserBio;
    }

    function saveUserBio() {
        localStorage.setItem('userProfile', JSON.stringify(userBio));
    }

    const [userBio, setUserBio] = useState(loadUserBio());
    const [inputActive, setInputActive] = useState(false);
    const [todayTotalKcal, setTodayTotalKcal] = useState(0);
    const [todayTotalProtein, setTodayTotalProtein] = useState(0);
    // Hide FAB by default
    const [hideFab, setHideFab] = useState(true);
    const [hideBottomNav, setHideBottomNav] = useState(false);

    // TODO lazyloading?
    const todaysMeals = useLiveQuery(
        () => {
            // Find the start of the day
            let startOfDay = new Date();
            startOfDay.setHours(0, 0, 0, 0);
            startOfDay = startOfDay.getTime();
            // Find the end of the day
            let endOfDay = new Date();
            endOfDay.setHours(24, 0, 0, 0);
            endOfDay = endOfDay.getTime();

            return db.kcalEntries.where('date').between(startOfDay, endOfDay, true, true).reverse().sortBy('date');
        }
    )


    const todaysWorkouts = useLiveQuery(
        () => {
            // Find the start of the day
            let startOfDay = new Date();
            startOfDay.setHours(0, 0, 0, 0);
            startOfDay = startOfDay.getTime();
            // Find the end of the day
            let endOfDay = new Date();
            endOfDay.setHours(24, 0, 0, 0);
            endOfDay = endOfDay.getTime();

            return db.workoutEntries.where('date').between(startOfDay, endOfDay, true, true).reverse().sortBy('date');
        }
    )



    function fetchMealEntry(id) {
        return db.kcalEntries.where({ id: parseInt(id) }).first();
    }

    function fetchWorkoutEntry(id) {
        console.log('hej' + id);
        return db.workoutEntries.where({ id: parseInt(id) }).first();
    }

    useEffect(() => {
        saveUserBio();
    }, [userBio]);

    useEffect(() => {
        let kcal = 0;
        let protein = 0;

        if (todaysMeals) {
            todaysMeals.forEach(item => {
                const k = parseInt(item.kcal);
                kcal += isNaN(k) ? 0 : k;
                const p = parseInt(item.protein);
                protein += isNaN(p) ? 0 : p;
            });

        }

        if (todaysWorkouts) {
            todaysWorkouts.forEach(item => {
                const k = parseInt(item.kcal);
                kcal -= isNaN(k) ? 0 : k;
            });

        }

        setTodayTotalKcal(kcal);
        setTodayTotalProtein(protein);

    }, [todaysMeals, todaysWorkouts]);


    const value = {
        userBio,
        todaysMeals,
        setUserBio,

        todayTotalKcal,
        todayTotalProtein,

        inputActive,
        setInputActive,

        hideFab,
        setHideFab,

        // Functions
        fetchMealEntry,
        insertMeal,
        insertWorkout,
        clearDatabase,
        deleteMealEntry,

        todaysWorkouts,
        fetchWorkoutEntry,
        deleteWorkoutEntry,

        hideBottomNav,
        setHideBottomNav

    }

    function deleteMealEntry(id) {
        return db.kcalEntries.where({ id: parseInt(id) }).delete();
    }


    function deleteWorkoutEntry(id) {
        return db.workoutEntries.where({ id: parseInt(id) }).delete();
    }


    function insertMeal(name, kcal, protein) {
        protein = isNaN(protein) ? 0 : protein;
        kcal = isNaN(kcal) ? 0 : kcal;

        db.kcalEntries.add({
            name: name,
            kcal: kcal,
            protein: protein,
            date: Date.now()
        });
    }


    function insertWorkout(name, kcal) {
        kcal = isNaN(kcal) ? 0 : kcal;

        db.workoutEntries.add({
            workoutName: name,
            kcal: kcal,
            date: Date.now()
        });
    }


    // Todo hydrate?


    function clearDatabase() {
        db.kcalEntries.clear();
    }

    return (
        <KcalContext.Provider value={value}>
            {props.children}
        </KcalContext.Provider>
    )

}