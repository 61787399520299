import React from 'react'
import { FaAngleLeft, FaChevronLeft } from 'react-icons/fa'
import { Link, useLocation, useNavigate } from 'react-router-dom'

export default function Topbar() {

    const navigate = useNavigate();

    const location = useLocation();
    const currentPage = location.pathname.split('/').pop();

    //const prevLocation = 
    return (
        <div id="topbar">
            <div className='body'>
                <div className='back' onClick={() => navigate(-1)}><FaChevronLeft className='icon' />Go back</div>
                <div className="title">{currentPage}</div>
            </div>
        </div>
    )
}
