import React from 'react'

export default function BoxSection(props) {
    return <div className='box-section'>
        {props.title &&
            <div className='title'>
                {props.title}
            </div>
        }
        {props.children}
    </div>;
}
