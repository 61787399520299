import React from 'react'

export default function Alert(props) {
    const types = {
        error: 'error',
        success: 'success',
        notice: 'notice',
        warning: 'warning'
    }
    return (
        <span className={'alert' + (props.type && props.type in types ? ' ' + types[props.type] : '')}>
            {props.children}
        </span>
    )
}
