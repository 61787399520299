import React from 'react'


export default function ProgressCircle(props) {

    // Calculate the percentage. If it happens to be above 100, math.min will give us 100 instead. If it's under 0, we'll get 0
    let percentage = Math.max(Math.min(Math.round((props.current / props.max) * 100), 100), 0);

    //let styling = diameter ? { height: props.diameter, width: props.diameter } : {};
    //TODO custom sizing
    const styling = {};

    const rightDegrees = 180 + (Math.min(percentage * (180 / 50), 180));
    const leftDegrees = 180 + (Math.max((percentage - 50), 0) * (180 / 50));

    return (
        <div className="circular-progress" style={styling}>
            <div className="inner-circle">
                {props.showPercentage && <>{percentage}%</>}
            </div>
            <div className="circle">
                <div className="left-circle" >
                    <div className="progress" style={{ transform: 'rotate(' + leftDegrees + 'deg)' }}></div>
                </div>
                <div className="right-circle" >
                    <div className="progress" style={{ transform: 'rotate(' + rightDegrees + 'deg)' }}></div>
                </div>
            </div>
        </div>
    )
}
