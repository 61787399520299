import React from 'react'

export default function Box(props) {
    return (
        <div className={'box ' + (props.className ? props.className : '')}>
            {
                props.title &&
                <div className='title'>
                    {props.title}
                </div>
            }

            {props.children}
        </div >
    )
}
