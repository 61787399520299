import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";

import React, { Suspense, lazy } from 'react'
import App from "./App";
import WithTopbar from "./screens/WithTopbar";
import Dashboard from "./screens/Dashboard";

const Progress = lazy(() => import("./screens/Progress"));
const NotFound404 = lazy(() => import("./screens/NotFound404"));
const Profile = lazy(() => import("./screens/profile/Profile"));
const Settings = lazy(() => import("./screens/profile/Settings"));
const About = lazy(() => import("./screens/profile/About"));

const MealsView = lazy(() => import("./screens/meal/MealsView"));
const ViewMeal = lazy(() => import("./screens/meal/ViewMeal"));
const ViewWorkout = lazy(() => import("./screens/workout/ViewWorkout"));
const WorkoutsView = lazy(() => import("./screens/workout/WorkoutsView"));
const LogEntry = lazy(() => import("./screens/LogEntry"));

const CurrentScreen = () => {
    return (
        <div>Loading...</div>
    )
}


export default function Router() {
    return (
        <BrowserRouter>
            <Suspense fallback={<CurrentScreen></CurrentScreen>}>
                <Routes>
                    <Route path="/" element={<App />}>
                        <Route index element={<Dashboard />} />
                        <Route path='dashboard' element={<Dashboard />} />
                        <Route path="logEntry" element={<LogEntry />} />
                        <Route path="progress" element={<Progress />} />

                        <Route path="profile/" element={<WithTopbar />} >
                            <Route index element={<Profile />} />
                            <Route path="/profile/settings" element={<Settings />} />
                            <Route path="/profile/about" element={<About />} />
                        </Route>

                        <Route path="meals/" element={<WithTopbar />} >
                            <Route index element={<MealsView />} />
                            <Route path=":mealId" element={<ViewMeal />} />
                        </Route>
                        <Route path="workouts/" element={<WithTopbar />} >
                            <Route index element={<WorkoutsView />} />
                            <Route path=":workoutId" element={<ViewWorkout />} />
                        </Route>

                        <Route path="*" element={<NotFound404 />} />
                    </Route>
                </Routes>
            </Suspense>
        </BrowserRouter>
    )
}
