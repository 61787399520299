import React from 'react'
import { Outlet } from 'react-router-dom';
import Box from '../components/common/Box'
import Topbar from '../components/Topbar';

export default function WithTopbar() {


    return (
        <Box>
            <Topbar />
            <Outlet />
        </Box>

    )
}
