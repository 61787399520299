import React from 'react'

export default function WorkoutCard(props) {

    const item = props.item;
    const time = new Date(item.date);
    const hour = ("0" + time.getHours()).slice(-2);
    const min = ("0" + time.getMinutes()).slice(-2);

    return (
        <li>
            <div className='time'>{hour + ':' + min}</div>
            <div className='details'>
                <div className='title'>{item.workoutName}</div>
                <div className='values'>
                    <span>{item.kcal} kcal</span>
                </div>
            </div>
        </li>
    )
}
