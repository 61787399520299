import React, { useEffect, useState } from 'react'
import Box from '../components/common/Box'
import BoxSection from '../components/common/BoxSection'
import DailySummary from '../components/DailySummary';
import { useKcalManager } from '../KcalContext';
import MealCard from '../components/MealCard';
import { Link } from 'react-router-dom';
import Alert from '../components/common/Alert';
import WorkoutCard from '../components/WorkoutCard';


export default function Dashboard() {

    const {
        todaysMeals,
        todaysWorkouts,
        setHideFab
    } = useKcalManager();

    const [todaysEntries, setTodaysEntries] = useState([]);


    //TODO hide fab with router instead
    useEffect(() => {
        document.title = "Sicaprot - Home"
        setHideFab(false);
        return () => setHideFab(true);
    }, [setHideFab]);

    useEffect(() => {

        // TODO optmize. No point sorting meals again if there's no worksout (as it's sorted on query)
        let toSort = [];

        if (todaysMeals) {
            toSort = [...toSort, ...todaysMeals];
        }

        if (todaysWorkouts) {
            toSort = [...toSort, ...todaysWorkouts];
        }

        // Sort descending
        toSort.sort((a, b) => b.date - a.date);

        setTodaysEntries(toSort);
    }, [todaysMeals, todaysWorkouts]);


    const NoEntriesYet = () => {
        return <>Nothing logged yet. Try logging a meal.</>
    }
    return (
        <>
            <Box>

                <BoxSection>
                    <DailySummary />
                </BoxSection>

                {
                    /* Nothing logged yet */
                    (todaysEntries && todaysEntries.length <= 0 &&
                        <BoxSection> <Alert type='notice'>Nothing here yet. Try adding a meal or workout entry!</Alert></BoxSection>)
                    || /* ELSE */
                    (todaysEntries && todaysEntries.length > 0) && (
                        <BoxSection>
                            <ul className='clear-ul meals'>
                                {todaysEntries.map((item, index) => {
                                    if (item.workoutName) {
                                        return <Link key={index} to={`/workouts/${item.id}`} className='no-style-link'><WorkoutCard item={item} /></Link>
                                    } else {
                                        return <Link key={index} to={`/meals/${item.id}`} className='no-style-link'><MealCard item={item} /></Link>
                                    }
                                }
                                )}
                            </ul>
                        </BoxSection>)
                }

                {/*<BentoMenu>
                    <Link to="/"><FaHome className='icon' />Home</Link>
                    <Link to="/"><FaPlusSquare className='icon' />Log Meal</Link>
                    <Link to="/"><FaChartBar className='icon' />Progress</Link>
                    <Link to="/"><FaCog className='icon' />Settings</Link>
                </BentoMenu>*/}

            </Box>
        </>
    )
}
