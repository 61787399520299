import React from 'react';
import ReactDOM from 'react-dom';
import './assets/index.css';
import './assets/commonUI.css';
import './assets/App.css';
import './assets/components.css';
import './assets/mobile.css';
import './assets/responsive.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { ChakraProvider } from '@chakra-ui/react'
import Router from './Router';
import { KcalProvider } from './KcalContext';

ReactDOM.render(
    <React.StrictMode>
        <ChakraProvider>
            <KcalProvider>
                <Router />
            </KcalProvider>
        </ChakraProvider >
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();