import React, { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import MainNavigation from './components/MainNavigation'

export default function App() {

    const location = useLocation();

    // Make sure to scroll to the top of the page whenever a screen change happens
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);


    return (
        <div id="main-wrapper">
            <MainNavigation />
            <Outlet />
        </div>
    )
}
