import React from 'react'
import { useKcalManager } from '../KcalContext'
import ProgressCircle from './common/ProgressCircle';

export default function DailySummary() {

    const {
        todayTotalKcal,
        todayTotalProtein,
        userBio
    } = useKcalManager();

    const date = new Date();

    const today = date.getDate();
    const month = date.toLocaleString('en-GB', { month: 'long' });


    return (
        <div>

            {/* TODO. HIDE BOTTOM NAV AND FAB when input is active */}


            <div className='day-summary-card'>
                <div className='date'>
                    {today + ' ' + month}
                </div>

                <ProgressCircle current={todayTotalKcal} max={userBio.kcalGoal} showPercentage />

                <div>
                    <div><strong>{userBio.kcalGoal}</strong> / {todayTotalKcal}  kcal </div>
                    <div><strong>{userBio.proteinGoal}</strong> / {todayTotalProtein} g</div>
                </div>
            </div>

        </div>
    )
}
