import Dexie from 'dexie';

const databaseName = 'sicaprotDatabase';
const DEVELOPMENT_MODE = false;

if (DEVELOPMENT_MODE) {
    // Delete current potential database to start fully fresh
    Dexie.delete(databaseName);
}

export const db = new Dexie(databaseName);

db.version(2).stores({
    kcalEntries: '++id, date, name, kcal, protein',
    workoutEntries: '++id, date, workoutName, kcal'
});/*.upgrade(tx => {
    var YEAR = 365 * 24 * 60 * 60 * 1000;
    return tx.table("friends").toCollection().modify(friend => {
        friend.birthdate = new Date(Date.now() - (friend.age * YEAR));
        delete friend.age;
    });
});*/


if (DEVELOPMENT_MODE) {


    let oneWeekAgo = new Date();
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

    const demoKcalEntries = [
        {
            name: 'chicken with broccoli',
            kcal: 500,
            protein: 70,
            date: Date.now()
        },
        {
            name: 'a snack',
            kcal: 400,
            protein: 10,
            date: Date.now()
        },

        // Old entry... a week ago
        {
            name: 'chocolate bar',
            kcal: 1200,
            protein: 23,
            date: oneWeekAgo.getTime()
        },


    ]

    const demoWorkoutEntries = [
        {
            workoutName: 'walk',
            kcal: 500,
            date: Date.now()

        }
    ]

    demoKcalEntries.forEach(item => db.kcalEntries.add(item));

    demoWorkoutEntries.forEach(item => db.workoutEntries.add(item));
}