import React from 'react'
import { FaPlus, FaHome, FaQuestionCircle, FaAppleAlt, FaChartBar, FaUserAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useKcalManager } from '../KcalContext';

export default function MainNavigation() {
    const { hideFab, hideBottomNav } = useKcalManager();

    return (
        <div id="main-navigation" className={hideBottomNav ? 'hide-on-mobile' : ''}>
            <div className='body'>

                <Link id="logo" to="/dashboard">
                    Sicaprot
                    <span>Simple tracking</span>
                </Link>

                <ul>
                    <li><Link to="/dashboard"><FaHome className='main-navigation-icon' />Home</Link></li>
                    <li className={'fab' + (hideFab ? ' hide-fab' : '')}><Link to="/logentry"><FaPlus className='main-navigation-icon' />Log Entry</Link></li>
                    <li><Link to="/meals"><FaAppleAlt className='main-navigation-icon' />Meals</Link></li>
                    <li><Link to="/progress"><FaChartBar className='main-navigation-icon' />Progress</Link></li>
                    <li><Link to="/profile"><FaUserAlt className='main-navigation-icon' />Profile</Link></li>
                </ul>
            </div>
        </div>
    )
}
